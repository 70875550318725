div#portal {
    div.overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;
    }
    div.modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 25px;
        z-index: 1000;
        button {
            position: absolute;
            width: 30px;
            height: 30px;
            font-size: 36px;
            right: 35px;
            top: 5px;
            cursor: pointer;
            p {
                position: absolute;
                top: -10px;
                right: -2px;
                padding: 0;
                margin: 0;
            }
        }
        img.modalImg {
            width: 95vw;
            margin: 15px;
            border: 1px solid black;
        }
    }
}
@media screen and (min-width: 920px) {
    div#portal {
        div.overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1000;
        }
        div.modal {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            padding: 25px;
            z-index: 1000;
            button {
                width: 30px;
                height: 30px;
                font-size: 36px;
                p {
                    position: absolute;
                    top: -10px;
                    left: 0px;
                    padding: 0;
                    margin: 0;
                }
            }
            img.modalImg {
                width: 1080px;
                margin: 15px;
                border: 1px solid black;
            }
        }
    }
}
