div.footer {
    background-color: $color-1;
    div.reseaux {
        display: table;
        max-width: 1140px;
        margin: auto;
        padding: 10px 0;
        h6 {
            font-size: 36px;
            margin: 0;
        }
        div.social {
            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 0;
                padding: 0;
                li {
                    list-style: none;
                    a {
                        padding: 0;
                        margin: 0;
                        div.img {
                            margin: 0 10px;

                            :hover {
                                border: 2px solid rgb(30, 218, 46);
                                border-radius: 50px;

                                background-color: rgb(30, 218, 46);
                            }

                            img {
                                margin: auto;
                                border: 2px solid rgb(30, 218, 46);
                                border-radius: 50px;
                                background-color: aliceblue;
                            }
                        }
                    }
                }
            }
        }
    }
}
