div.portfolio {
    h2 {
        background-color: $color-1;
        font-size: 26px;
        text-align: center;
        // width: 100vw;
        padding: 10px 32px;
    }
    div.adpose {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: auto;

        li {
            margin: 5px;
            width: 90%;
            img {
                border: 1px solid black;
                width: 100%;
            }
            img:onclick {
                position: absolute;
                width: 550px;
            }
        }
    }
}

@media screen and (min-width: 920px) {
    div.portfolio {
        h2 {
            background-color: $color-1;
            font-size: 26px;
            text-align: center;
            padding: 10px 32px;
        }
        div.adpose {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: auto;

            li {
                margin: 10px;
                width: 40%;
                img {
                    border: 1px solid black;
                    width: 100%;
                    cursor: pointer;
                }
                img:onclick {
                    position: absolute;
                    width: 550px;
                }
            }
        }
    }
}
