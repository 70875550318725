div.topPortfolio {
    background-image: url("../assets/images/portfolio.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
    div.fixed {
        div.nav {
            div.logo {
                h1 {
                    position: absolute;
                    margin: 0;
                    top: calc((70px / 2) - (33px / 2));
                    left: 5vw;
                }
            }
            nav {
                // width: 50px;
                input#checkbox_hamburger {
                    position: absolute;
                    display: none;
                }
                input#checkbox_hamburger:checked ~ label p.code_hamburger {
                    transform: translateY(0px);
                }
                input#checkbox_hamburger:checked ~ label p.code_cross {
                    transform: translateY(-40px);
                }
                input#checkbox_hamburger:not(:checked) ~ label p.code_hamburger {
                    transform: translateY(-40px);
                }
                input#checkbox_hamburger:not(:checked) ~ label p.code_cross {
                    transform: translateY(0px);
                }
                input#checkbox_hamburger:checked ~ ul {
                    height: calc(38px * $nbr_li);
                }
                input#checkbox_hamburger:not(:checked) ~ ul {
                    height: 0;
                }
                label.hamburger {
                    right: 5vw;
                    position: absolute;
                    width: 33px;
                    height: 33px;
                    top: calc((70px / 2) - (33px / 2));
                    cursor: pointer;
                    overflow: hidden;
                    p.code_hamburger {
                        position: absolute;
                        font-size: 36px;
                        width: 33px;
                        height: 33px;
                        transition: transform 400ms ease-in-out;
                        top: -2px;
                        left: 8px;
                    }
                    p.code_cross {
                        position: absolute;
                        font-size: 36px;
                        width: 33px;
                        height: 33px;
                        transition: transform 400ms ease-in-out;
                        top: -2px;
                        left: 8px;
                        z-index: 1000;
                    }
                }
                ul {
                    list-style-type: none;
                    position: absolute;
                    margin: 0;
                    width: 100vw;
                    height: 100vh;
                    padding: 0;
                    overflow: hidden;
                    transition: width 400ms ease-in-out;
                    background-color: rgb(46, 46, 46);
                    a {
                        color: inherit;
                        text-decoration: none;
                        li {
                            position: relative;
                            text-align: center;
                            padding: 10px;
                            width: 60%;
                            top: 45vh;
                            transition:
                                color 100ms ease-in-out,
                                background-color 100ms ease-in-out;
                            width: 100%;
                            border: 1px solid $dark-grey;
                            color: rgb(198, 195, 195);
                            font-weight: bold;
                        }
                        li:hover {
                            background-color: $dark-grey;
                            color: $white;
                        }
                    }
                    li.cv {
                        text-align: center;
                        position: relative;
                        top: 45vh;
                        padding: 10px;
                        width: 60%;
                        margin: auto;
                        transition:
                            color 100ms ease-in-out,
                            background-color 100ms ease-in-out;
                        width: 100%;
                        border: 1px solid $dark-grey;
                        color: rgb(198, 195, 195);
                        font-weight: bold;
                        p {
                            margin: 0px;
                        }
                    }
                    li:hover {
                        background-color: $dark-grey;
                        color: $white;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 600px) {
    div.topPortfolio {
        height: 23vh;
    }
}
@media screen and (min-width: 800px) {
    div.topPortfolio {
        height: 25vh;
    }
}
@media screen and (min-width: 900px) {
    div.topPortfolio {
        div.fixed {
            div.nav {
                nav {
                    label.hamburger {
                        left: 90vw;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    div.topPortfolio {
        height: 40vh;
        div.fixed {
            div.nav {
                nav {
                    label.hamburger {
                        left: 93vw;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    div.topPortfolio {
        div.fixed {
            position: fixed;
            min-height: 70px;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;

            &.nav-active {
                background-color: rgb(39, 38, 38);
                box-shadow: 5px -1px 12px -5px grey;
            }
            div.nav {
                display: flex;
                margin: 0 20px;
                width: 100%;
                nav {
                    input#checkbox_hamburger:not(:checked) ~ ul {
                        height: 100%;
                    }
                    input {
                        display: none;
                    }
                    label {
                        display: none;
                    }
                    ul {
                        display: flex;
                        left: 70vw;
                        top: -15px;
                        background-color: inherit;
                        a {
                            margin: 10px;
                            font-weight: bold;
                            font-size: 25px;
                            li {
                                margin: 0;
                                color: $white;
                                border: none;
                                top: calc((70px / 2) - (33px / 2));
                            }
                            li:hover {
                                background-color: inherit;
                            }
                        }
                        li.cv {
                            margin: 10px;
                            font-weight: bold;
                            font-size: 25px;
                            border: none;
                            width: max-content;
                            display: flex;
                            color: $white;
                            cursor: pointer;
                            top: calc((70px / 2) - (33px / 2));
                            p {
                                vertical-align: middle;
                                justify-content: center;
                                margin: auto;
                            }
                        }
                        li:hover {
                            background-color: inherit;
                        }
                    }
                }
            }
        }
    }
}
