@keyframes clignote {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

div.topAccueil {
    div.presentation {
        position: relative;
        z-index: 5;
        top: 35vh;
        h2 {
            text-align: center;
            font-size: 60px;
            margin: 0;
            z-index: 5;
        }
        h3 {
            text-align: center;
            font-size: 32px;
            margin: 0;
            z-index: 5;
        }
        ul {
            display: flex;
            justify-content: center;
            margin-top: 100px;
            padding: 0;
            z-index: 5;
            li {
                background-color: rgba(58, 58, 58, 0.485);
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                // margin-top: 15px;
                font-size: 22px;
                padding: 10px;
                border: 2px solid;
                border-color: $white;
                border-radius: 30px;
                list-style: none;
                height: 30px;
                cursor: pointer;
                animation-duration: 3s;
                animation-name: clignote;
                animation-iteration-count: infinite;
            }
            li:hover {
                background-color: rgb(48, 201, 252);
                transition: 500ms ease-in;
                animation-name: clignote;
                animation-duration: 0s;
            }
        }
        a.arrow {
            display: flex;
            justify-content: center;
            margin-top: 15vh;
            animation-duration: 3s;
            animation-name: clignote;
            animation-iteration-count: infinite;
            z-index: 5;

            :hover {
                background-color: rgb(48, 201, 252);
                transition: 500ms ease-in;
                animation-name: clignote;
                animation-duration: 0s;
            }
            img {
                transform: rotate(90deg);
                border: 2px solid;
                border-radius: 20px;
                padding: 5px;
                background-color: #c4cdca;
                z-index: 5;
            }
        }
    }
}
div.AproposArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 35vw;
        margin: 0 25px;
    }
    div.Apropos {
        margin: 0 45px;
        color: black;
        h4 {
            font-size: 32px;
        }
        div.About {
            white-space: pre-line;
            margin-top: 15px;
            font-size: 26px;
            margin-bottom: 30px;
        }
    }
}
div.skills {
    div.title {
        background-color: rgb(33, 33, 33);
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
            font-size: 42px;
            margin: auto;
        }
    }
    div.filtres {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 50px 25px 20px 25px;
        li {
            margin-top: 50px;
            list-style: none;
            margin: 20px 10px;
            :hover {
                border: 3px solid rgb(48, 201, 252);
                border-radius: 50px;
                width: 75px;
                background-color: rgb(48, 201, 252);
                cursor: pointer;
            }

            input[type="radio"].typeTech {
                display: none;
            }
            label {
                list-style: none;
                padding: 10px;
                border: 1px solid $color-1;
                border-radius: 50px;
                color: black;
            }
            label.checked {
                margin-top: 50px;
                list-style: none;
                margin: 20px 10px;
                border: 3px solid rgb(48, 201, 252);
                border-radius: 50px;
                // width: 75px;
                background-color: rgb(48, 201, 252);
            }
        }
    }
    div.list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px 0px 50px 0;
        div.card {
            margin: 15px;
            img {
                width: 105px;
            }
        }
    }
}

@media screen and (min-width: 920px) {
    div.AproposArea {
        flex-direction: row;
    }
    div.skills {
        div.filtres {
            font-size: 21px;
        }
        div.list {
            margin: 20px 100px 50px 100px;
            div.card {
                img {
                    width: 200px;
                }
            }
        }
    }
}
