div.FormContact {
    h4 {
        background-color: $color-1;
        text-align: center;
        font-size: 26px;
        padding: 10px 32px;
    }
    div.sent {
        font-size: 26px;
        text-align: center;
        color: $color-1;
        font-weight: bold;
        border: solid 2px $color-1;
        margin: 20% 20px;
        padding: 25px;
        h5 {
            font-size: 32px;
        }
    }
    form {
        color: black;
        display: flex;
        flex-direction: column;
        margin: 25px 15px;

        div {
            display: flex;
            flex-direction: column;
            margin: 10px 0px;
        }
        button {
            background-color: rgb(30, 218, 46);
            width: 80px;
            height: 32px;
            color: $white;
            font-weight: bold;
            border-radius: 20px;
            border: none;
        }
    }
}

@media screen and (min-width: 920px) {
    div.FormContact {
        div.sent {
            margin: 50px 25%;
        }
        form {
            margin: 25px 25%;

            button {
                cursor: pointer;
            }
        }
    }
}
