@font-face {
    font-family: "font-1";
    src: url(../styles/assets/fonts/JosefinSans-VariableFont_wght.ttf);
}

$font-1: "font-1", sans-serif;

$color-1: #2b2b2b;
$white: rgb(243, 243, 243);
$dark-grey: #4b4a4a;
$color-grey: #f2f2f2;
$nbr_li: 4;

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    color: $white;
    font-family: $font-1;
    margin: 0;
}
a {
    text-decoration: none;
    color: inherit;
    li {
        list-style-type: none;
    }
}
